import {Link} from "react-router-dom";


function Footer() {
    return (
        <footer className="footer-area theme-footer-three pt-145 pt-lg-100 pt-sm-100">
            <img className="footer-shape shape-1b" src="/lib/techy/img/shape/star-3b.svg" alt="shape" />
            <img className="footer-shape shape-2b" src="/lib/techy/img/shape/star-4b.svg" alt="shape" />
            <img className="footer-shape shape-3b" src="/lib/techy/img/shape/star-6b.svg" alt="shape" />
            <img className="footer-shape shape-4b" src="/lib/techy/img/shape/star-5b.svg" alt="shape" />
            <img className="footer-shape shape-5b" src="/lib/techy/img/shape/star-5b.svg" alt="shape" />
            <img className="fot-shape-one" src="/lib/techy/img/shape/line-round-7b.svg" alt="footer shape" />
            <div className="container">
                <div className="row gx-4 gx-xxl-5 mb-10">
                    <div className="col-xxl-3 col-md-4">
                        <div className="footer__widget mb-40">
                            <Link className="" to="/"></Link>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-md-4">
                        <div className="footer__widget mb-40">
                            <h4 className="widget-title">Contacto</h4>
                            <ul className="fot-list">
                                <li><a href="mailto:contacto@ondersoft.com.co">contacto@ondersoft.com.co</a></li>
                                <li><a href="tel:+57 310 604 1535">+57 310 604 1535</a></li>
                                <li><a href="#">Colombia,<br /> Santander de Quilichao</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-three">
                <div className="container">
                    <div className="row gx-4 gx-xxl-5 pt-60 align-items-center">
                        <div className="col-xl-4 col-lg-6 pb-30">
                            <ul className="fot-list text-center d-sm-flex align-items-center justify-content-center justify-content-lg-start">
                                <li><Link to="/">FAQ</Link></li>
                                <li><Link to="/">Terminos y condiciones</Link></li>
                                <li><Link to="/">Politicas de privacidad</Link></li>
                            </ul>
                        </div>
                        <div className="col-xl-4 col-lg-6 text-center pb-30">
                            <p className="text-white mb-20">
                                <a href="https://www.ondersoft.com.co/">OnderSoft</a>
                                © 2024, Todos los derechos reservados.
                            </p>
                        </div>
                        <div className="col-xl-4 col-lg-6 d-flex justify-content-center justify-content-lg-start justify-content-xl-end pb-30">
                            <div className="footer__social mb-30">
                                <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                                <Link to="#"><i className="fab fa-twitter"></i></Link>
                                <Link to="#"><i className="fab fa-linkedin-in"></i></Link>
                                <Link to="#"><i className="fab fa-youtube"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;


