import React, {useEffect, useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import Aside from "./aside";

function ButtonShowMenu({ onClick }) {
    return (
        <a className="round-menu" href="#" onClick={onClick}>
            <i className="bi bi-list"></i>
        </a>
    );
}

function Navbar() {

    const [hasClass, setHasClass] = useState(false);
    const toggleClass = () => {
        setHasClass(!hasClass);
    };

    return (
        <header className="theme-main-menu theme-menu-three">
            <div className="main-header-area">
                <div className="container custom-container-nav">
                    <div className="row gx-4 gx-xxl-5 align-items-center">

                        <div className="col-xxl-3 col-xl-2 col-lg-2 col-6 text-start">
                            <div className="logo-area">
                                <Link to="/">
                                    <img className="navbar-logo" src="/only-logo.png" alt="Header-logo"/>
                                </Link>
                            </div>
                        </div>

                        <div className="col-xxl-6 col-xl-6 col-lg-6 d-none d-lg-block">
                            <nav className="navbar navbar-expand-lg">
                                <div className="container-fluid">
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#navbarSupportedContent"
                                            aria-controls="navbarSupportedContent"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav mb-2 mb-lg-0 ps-1">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/">Inicio</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/nosotros">Nosotros</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/contacto">Contacto</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div className="col-xxl-3 col-xl-4 col-lg-4 col-6 text-end">
                            <ul className="d-sm-block d-md-none right-nav mb-0 d-flex align-items-center justify-content-end">
                                <li>
                                    <div className="hamburger-menu">
                                        <ButtonShowMenu onClick={toggleClass} />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Aside className={hasClass ? 'show' : ''} />
        </header>
    );
}

export default Navbar;
