
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter as Router, Route, Routes, Switch} from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import Preloader from "./components/preloader";
import Navbar from "./components/navbar";
import OffCanvas from "./components/offCanvas";
import Footer from "./components/footer";
import Main from "./Main";
import Aside from "./components/aside";
import AOS from "aos";

    AOS.init();

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <Router>
            <div className="main-page-wrapper">
                <React.StrictMode>
                    <Navbar />
                    {/* Habilitar Preloader una vez se termine todo el desarrollo, o al gusto.*/}
                    {/*<Preloader />*/}
                    <OffCanvas />
                    <Aside />
                    <Main />
                    <Footer />
                </React.StrictMode>
            </div>
        </Router>
    );
    reportWebVitals();
