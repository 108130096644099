

function OffCanvas() {
    return(
        <div className="offcanvas offcanvas-top theme-bg" id="offcanvasTop" aria-labelledby="offcanvasTop">
            <div className="offcanvas-header">
                <div data-bs-dismiss="offcanvas" aria-label="Close">
                    <i className="fas fa-times search-close" id="search-close"></i>
                </div>
            </div>
            <div className="offcanvas-body">
                <div className="search-wrap">

                </div>
            </div>
        </div>
    );
}
export default OffCanvas;

