import {Link} from "react-router-dom";

function Proyectos() {
    return(
        <main>
            <div className="page-title-area pt-210 pb-150 pt-lg-120 pb-lg-125 pb-md-100">
                <img className="page-title-shape shape-one " src="/lib/techy/img/shape/star-10d.svg" alt="shape"/>
                <img className="page-title-shape shape-three" src="/lib/techy/img/shape/star-2.svg" alt="shape"/>
                <img className="page-title-shape shape-four d-none d-md-inline-block " src="/lib/techy/img/shape/star-4b.svg"
                     alt="shape"/>
                <img className="page-title-shape shape-five d-none d-lg-inline-block " src="/lib/techy/img/shape/line-round-9d.svg"
                     alt="shape"/>
                <img className="page-title-shape shape-six" src="/lib/techy/img/shape/star-5b.svg" alt="shape"/>
                <img className="page-title-shape shape-seven" src="/lib/techy/img/shape/star-5b.svg" alt="shape"/>
                <img className="page-title-shape shape-eight d-none d-xl-inline-block" src="/lib/techy/img/page-title/img-01.png"
                     alt="img"/>
                <img className="page-title-shape shape-nine d-none d-xl-inline-block" src="/lib/techy/img/page-title/img-02.png"
                     alt="img"/>

                <div className="container">
                    <div className="row gx-4 gx-xxl-5 align-items-center">
                        <div className="col-xl-6 col-md-6">
                            <div className="page-title-wrapper text-md-start text-center">
                                <h2 className="page-title mb-20">Our Project</h2>
                                <nav aria-label="breadcrumb">
                                    <ul className="breadcrumb list-none justify-content-center justify-content-md-start">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link href="#">Pages</Link></li>
                                        <li className="active" aria-current="page">Project Grid</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="techy-project-one pt-145 pb-105 pt-lg-55 pb-lg-15">
                <div className="container">
                    <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-8">
                            <div className="section-title text-center mb-25">
                                <h3 className="sect-title mb-25" data-aos="fade-up">Featured Works</h3>
                                <p data-aos="fade-up">Dramatically supply transparent
                                    backward deliverables before caward comp internal or "organic" sources.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xxl-10 text-center">
                            <div className="portfolio-menu mb-40" data-aos="fade-up" data-aos-delay="100">
                                <button className="gf_btn active" data-filter="*">All</button>
                                <button className="gf_btn" data-filter=".cat1">Software</button>
                                <button className="gf_btn" data-filter=".cat2">Web Security</button>
                                <button className="gf_btn" data-filter=".cat3">IT Solutions</button>
                                <button className="gf_btn" data-filter=".cat4">Technology</button>
                                <button className="gf_btn" data-filter=".cat5">Cyber Security</button>
                            </div>
                        </div>
                    </div>
                    <div className="grid row gx-4 gx-xxl-5 feature-slider-one pe-0" data-aos="fade-up"
                         data-aos-delay="200">
                        <div className="col-lg-4 col-md-4 col-sm-6 grid-item cat3 cat5">
                            <div className="feature-item mb-40">
                                <a className="back-bg" href="project-details.html">
                                    <img className="w-100" src="/lib/techy/img/work/project-1.jpg" alt="img"/>
                                </a>
                                <h5>
                                    <a href="project-details.html">Web Design</a>
                                </h5>
                                <h3><a href="project-details.html">Web Analytics</a></h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 grid-item cat2 cat4">
                            <div className="feature-item mb-40">
                                <a className="back-bg" href="project-details.html">
                                    <img className="w-100" src="/lib/techy/img/work/project-2.jpg" alt="img"/>
                                </a>
                                <h5>
                                    <a href="project-details.html">Cyber Secure</a>
                                </h5>
                                <h3><a href="project-details.html">Cyber Security Core</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center mt-10 mb-45">
                            <a className="theme_btn" href="#">Load More</a>
                        </div>
                    </div>
                </div>

            </section>

            <section className="call-to-action pt-100 pb-100">
                <img className="call-shape shape-one" src="/lib/techy/img/shape/line-round-8c.svg" alt="shape"/>
                <img className="call-shape shape-two d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-39c.svg" alt="icon"/>
                <img className="call-shape shape-three d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-40c.svg"
                     alt="icon"/>
                <img className="call-shape shape-four d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-41c.svg" alt="icon"/>
                <img className="call-shape shape-five d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-42c.svg" alt="icon"/>
                <img className="call-shape shape-six d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-43c.svg" alt=""/>
                <img className="call-shape shape-seven d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-44c.svg" alt=""/>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-md-8">
                            <div className="call-to-wrapper">
                                <h3 className="title-big mb-60" data-aos="fade-up">
                                    Have a project in mind? Let’s get to work.
                                </h3>
                                <Link className="theme_btn" data-aos="fade-up" to="/contacto">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Proyectos;




