
import {Link} from "react-router-dom";
import {useState} from "react";

function Aside({className}) {

    const [hasClass, setHasClass] = useState(true);
    const closeAside = () => {
        const asides = document.querySelectorAll('aside');
        asides.forEach(aside => {
            aside.classList.remove('show');
        });
        setHasClass(false);
    };

    return (
        <div>
            <aside className={`slide-bar ${hasClass ? '' : 'show'} ${className}`}>
                <div className="close-mobile-menu">
                    <a href="#!" onClick={closeAside}>
                        <i className="fas fa-times"></i>
                    </a>
                </div>
                <div className="offset-sidebar">
                    <div className="offset-widget offset-logo mb-30">
                        <Link to="/">
                            <img className="" src="/only-logo.png" alt="logo"/>
                        </Link>
                    </div>
                    <div className="offset-widget mb-40">
                        <div className="info-widget">
                            <h4 className="offset-title mb-20">About Us</h4>
                            <p className="mb-30">
                                But I must explain to you how all this mistaken idea of denouncing pleasure and
                                praising pain was born and will give you a complete account of the system and
                                expound the actual teachings of the great explore
                            </p>
                        </div>
                    </div>
                    <div className="offset-widget mb-30 pr-10">
                        <div className="info-widget info-widget2">
                            <h4 className="offset-title mb-20">Contact Info</h4>
                            <p>
                                <i className="fal fa-address-book"></i>
                                23/A, Miranda City Likaoli Prikano, Dope</p>
                            <p>
                                <i className="fal fa-phone"></i>
                                +0989 7876 9865 9
                            </p>
                            <p>
                                <i className="fal fa-envelope-open"></i>
                                info@example.com
                            </p>
                        </div>
                    </div>
                    <div className="login-btn text-center">
                        <a className="theme_btn w-100" href="login.html">Login</a>
                    </div>
                </div>
                <nav className="side-mobile-menu">
                    <ul id="mobile-menu-active">
                        <li><Link onClick={closeAside} to="/">Inicio</Link></li>
                        <li><Link onClick={closeAside} to="/nosotros">Nosotros</Link></li>
                        <li><Link onClick={closeAside} to="/contacto">Contacto</Link></li>
                    </ul>
                </nav>
            </aside>
            <div className="body-overlay"></div>
        </div>
        );
}
export default Aside;


