
    import {Link} from "react-router-dom";

    function Inicio() {
       
        return(
            <main>

                <div className="theme-banner-three pt-100 pb-155 pt-lg-120 pb-lg-100 pt-md-80 pb-md-40">
                    <img className="banner-shape shape-one d-none d-sm-inline-block"
                         src="/lib/techy/img/shape/star-3b.svg"
                         alt="shape"/>
                    <img className="banner-shape shape-two d-none d-lg-inline-block"
                         src="/lib/techy/img/shape/star-4b.svg"
                         alt="shape"/>
                    <img className="banner-shape shape-three d-none d-sm-inline-block"
                         src="/lib/techy/img/shape/star-2.svg"
                         alt="shape"/>
                    <img className="banner-shape shape-four d-none d-md-inline-block "
                         src="/lib/techy/img/shape/star-4b.svg" alt="shape"/>
                    <img className="banner-shape shape-five" src="/lib/techy/img/shape/star-7c.svg" alt="shape"/>
                    <img className="banner-shape shape-six d-none d-xl-inline-block"
                         src="/lib/techy/img/hero/hero-shape-3a.svg" alt="shape"/>
                    <img className="banner-shape shape-seven d-none d-xl-inline-block"
                         src="/lib/techy/img/hero/hero-shape-3b.svg" alt="shape"/>
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center">
                            <div className="col-xl-6 col-md-6">
                                <div className="theme__content__two text-md-start text-center mb-45">
                                    <h4 className="hero-sub-title mb-25 init" data-aos="fade-right">Desarrolladora de
                                        Software Reconocida</h4>
                                    <h1 className="main-title-two" data-aos="fade-left">
                                        Impulsa tu Presencia Digital
                                        <br/>
                                        con <span>OnderSoft</span>
                                    </h1>
                                    <Link className="theme_btn" to="/contacto">Obtén Nuestra Consulta</Link>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="hero-ilustration-wrapper mb-45">
                                    <img data-aos="fade-right" src="/lib/techy/img/hero/hero-3c.svg" alt="ilus"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="brand-area position-relative pt-75 pb-70 pt-lg-50 pb-lg-20">
                    <div className="blur-shape"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center mb-55">
                                <h2 className="sect-title">Más de
                                    <span className="text-theme">100+</span>
                                    Empresas de Confianza</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-techy bg-shape-3c pt-40 pb-105 pt-lg-60 pb-lg-10">
                    <img className="about-shape-3c shape-1b d-none d-xl-inline-block"
                         src="/lib/techy/img/shape/star-3b.svg"
                         alt="shape"/>
                    <img className="about-shape-3c shape-2b d-none d-md-inline-block"
                         src="/lib/techy/img/shape/star-4b.svg"
                         alt="shape"/>
                    <img className="about-shape-3c shape-3b d-none d-lg-inline-block"
                         src="/lib/techy/img/shape/star-4b.svg"
                         alt="shape"/>
                    <img className="about-shape-3c shape-4b" src="/lib/techy/img/shape/star-7c.svg" alt="shape"/>
                    <img className="about-shape-3c shape-5b d-none d-xl-inline-block"
                         src="/lib/techy/img/shape/star-4b.svg " alt="shape"/>
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="about-img-wrapper-three text-lg-end position-relative mb-45">
                                    <img className="main-img" src="/lib/techy/img/about/ilus-1c.svg" alt="ilustrate"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="block-style-seven pl-90 mb-45 pl-md-0">
                                    <h6 className="sub-title-two mb-20" data-aos="fade-right">Acerca de OnderSoft</h6>
                                    <h2 className="sect-title mb-20">Desarrollo Seguro de Aplicaciones Web y
                                        Móviles</h2>
                                    <p>En OnderSoft nos especializamos en garantizar la seguridad en el desarrollo de
                                        aplicaciones web y móviles.
                                        Constantemente ideamos estrategias que cumplen con los más altos estándares de
                                        seguridad.
                                        Optamos por soluciones alternativas globalmente reconocidas y optimizamos el
                                        rendimiento.</p>
                                    <div className="icon-box">
                                        <span><img src="/lib/techy/img/icon/icon-28c.svg" alt="icon"/></span>
                                        <span><img src="/lib/techy/img/icon/icon-29c.svg" alt="icon"/></span>
                                        <span><img src="/lib/techy/img/icon/icon-30c.svg" alt="icon"/></span>
                                        <span><img src="/lib/techy/img/icon/icon-31c.svg" alt="icon"/></span>
                                        <span><img src="/lib/techy/img/icon/icon-32c.svg" alt="icon"/></span>
                                    </div>
                                    <Link data-aos="fade-left" className="theme_btn mt-55" to="/nosotros">Más
                                        Información</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="gradient-bg our-services service-bg-three pt-95 pb-55 pt-lg-55 pb-lg-15">
                    <img className="service-shape-3c" src="/lib/techy/img/shape/line-round-6b.svg" alt="shape"/>
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="section-title text-center mb-55">
                                    <h3 className="sect-title mb-25">Nuestros Servicios</h3>
                                    <p>En OnderSoft, ofrecemos una amplia gama de servicios para el desarrollo y la
                                        seguridad de tus aplicaciones web y móviles.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-4 col-md-6">
                                <div className="feature-style-five mb-45">
                                    <div className="icon mb-30">
                                        <img data-aos="fade-right" src="/lib/techy/img/icon/icon-33c.svg" alt="icon"/>
                                    </div>
                                    <h2 className="mb-20">
                                        <a className="sect-title-two" href="#">Desarrollo de
                                            Aplicaciones Web y Móviles
                                        </a>
                                    </h2>
                                    <p>Diseñamos y desarrollamos aplicaciones web y móviles a medida para satisfacer tus
                                        necesidades comerciales.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="feature-style-five mb-45">
                                    <div className="icon mb-30">
                                        <img data-aos="fade-right" src="/lib/techy/img/icon/icon-34c.svg" alt="icon"/>
                                    </div>
                                    <h2 className="mb-20">
                                        <a className="sect-title-two" href="#">Optimización en la Nube</a>
                                    </h2>
                                    <p>Implementamos soluciones de infraestructura en la nube para mejorar el
                                        rendimiento y la escalabilidad de tus aplicaciones.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="feature-style-five mb-45">
                                    <div className="icon mb-30">
                                        <img data-aos="fade-right" src="/lib/techy/img/icon/icon-35c.svg" alt="icon"/>
                                    </div>
                                    <h2 className="mb-20">
                                        <a className="sect-title-two" href="#">Desarrollo Frontend y
                                            Backend</a>
                                    </h2>
                                    <p>Desarrollamos tanto el frontend como el backend de tus aplicaciones para
                                        garantizar una experiencia de usuario fluida y segura.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="feature-style-five mb-45">
                                    <div className="icon mb-30">
                                        <img data-aos="fade-right" src="/lib/techy/img/icon/icon-36c.svg" alt="icon"/>
                                    </div>
                                    <h2 className="mb-20">
                                        <a className="sect-title-two" href="#">Base de Datos
                                            Segura</a>
                                    </h2>
                                    <p>Implementamos y gestionamos bases de datos seguras para garantizar la integridad
                                        y la confidencialidad de tus datos.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="feature-style-five mb-45">
                                    <div className="icon mb-30">
                                        <img data-aos="fade-right" src="/lib/techy/img/icon/icon-37c.svg" alt="icon"/>
                                    </div>
                                    <h2 className="mb-20">
                                        <a className="sect-title-two" href="#">Seguridad Web
                                            Avanzada</a>
                                    </h2>
                                    <p>Implementamos medidas de seguridad avanzadas para proteger tus aplicaciones web
                                        de amenazas cibernéticas.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="feature-style-five mb-45">
                                    <div className="icon mb-30">
                                        <img data-aos="fade-right" src="/lib/techy/img/icon/icon-38c.svg" alt="icon"/>
                                    </div>
                                    <h2 className="mb-20">
                                        <a className="sect-title-two" href="#">Desarrollo de API
                                            Seguro</a>
                                    </h2>
                                    <p>Desarrollamos y aseguramos interfaces de programación de aplicaciones (API) para
                                        garantizar la interoperabilidad y la seguridad de tus sistemas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="teachy-work work-bg-three pt-150 pb-30 pt-lg-50 pb-lg-20">
                    <img className="work-shape-c shape-one" src="/lib/techy/img/shape/star-7c.svg" alt="shape"/>
                    <img className="work-shape-c shape-two" src="/lib/techy/img/shape/star-7c.svg" alt="shape"/>
                    <img className="work-shape-c shape-three" src="/lib/techy/img/shape/star-8c.svg" alt="shape"/>
                    <img className="work-shape-c shape-four" src="/lib/techy/img/shape/star-8c.svg" alt="shape"/>
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center pb-30">
                            <div className="col-lg-6">
                                <div className="feature-img-wrapper mb-45">
                                    <img src="/lib/techy/img/ilustration/ilus-01.svg" alt="ilustration"/>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-first">
                                <div className="section-title mb-45 pe-xxl-5">
                                    <h6 className="sub-title-two mb-20">Desarrollo de Aplicaciones Móviles</h6>
                                    <h3 className="sect-title mb-25">Innovación con Reconocimiento Facial</h3>
                                    <p className="mb-15">En OnderSoft, ofrecemos soluciones avanzadas de seguridad,
                                        incluyendo el reconocimiento facial para proteger tus sistemas y datos.</p>
                                    <p>Continuamente ideamos estrategias que cumplen con los estándares en lugar de
                                        alineaciones basadas en el rendimiento. Aprovechamos soluciones alternativas
                                        existentes a través de usuarios virtuales.</p>
                                    <a className="theme_btn mt-55" href="project-details.html">Más Detalles</a>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xxl-5 align-items-center pt-75 pt-lg-55">
                            <div className="col-lg-6">
                                <div className="feature-img-wrapper mb-45">
                                    <img src="/lib/techy/img/ilustration/ilus-02.svg" alt="ilustration"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="block-style-eight mb-45 ps-xxl-5">
                                    <h6 className="sub-title-two mb-20">Seguridad en Aplicaciones Web y Móviles</h6>
                                    <h3 className="sect-title mb-25">Protección con Huella Digital</h3>
                                    <ul className="block-list list-none">
                                        <li>Continuamente ideamos estrategias que cumplen con los estándares.</li>
                                        <li>Aprovechamos soluciones alternativas existentes a través de usuarios
                                            virtuales.
                                        </li>
                                        <li>Basamos nuestras alineaciones en el rendimiento y en estándares de
                                            calidad.
                                        </li>
                                    </ul>
                                    <a className="theme_btn mt-35" href="project-details.html">Más Detalles</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="techy-security pt-75 pb-150 pt-lg-55 pb-lg-60">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 justify-content-center">
                            <div className="col-lg-6 col-md-8">
                                <div className="section-title text-center mb-55">
                                    <h6 className="sub-title-two mb-20">Seguridad de Carteras Digitales</h6>
                                    <h3 className="sect-title">Protege tu Cartera Digital con OnderSoft</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12">
                                <div className="ilustration-wrapper">
                                    <img className="w-100" src="/lib/techy/img/ilustration/ilus-03.svg"
                                         alt="ilustración"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="call-to-action pt-100 pb-100">
                    <img className="call-shape shape-one" src="/lib/techy/img/shape/line-round-8c.svg" alt="forma"/>
                    <img className="call-shape shape-two d-none d-lg-inline-block"
                         src="/lib/techy/img/icon/icon-39c.svg"
                         alt="ícono"/>
                    <img className="call-shape shape-three d-none d-lg-inline-block"
                         src="/lib/techy/img/icon/icon-40c.svg"
                         alt="ícono"/>
                    <img className="call-shape shape-four d-none d-lg-inline-block"
                         src="/lib/techy/img/icon/icon-41c.svg"
                         alt="ícono"/>
                    <img className="call-shape shape-five d-none d-lg-inline-block"
                         src="/lib/techy/img/icon/icon-42c.svg"
                         alt="ícono"/>
                    <img className="call-shape shape-six d-none d-lg-inline-block"
                         src="/lib/techy/img/icon/icon-43c.svg"
                         alt=""/>
                    <img className="call-shape shape-seven d-none d-lg-inline-block"
                         src="/lib/techy/img/icon/icon-44c.svg"
                         alt=""/>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-md-8">
                                <div className="call-to-wrapper">
                                    <h3 className="title-big mb-60">
                                        ¿Tienes un proyecto en mente? ¡Pongámonos a trabajar!
                                    </h3>
                                    <Link className="theme_btn" to="/contacto">Contáctanos</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        );
    }

    export default Inicio;
