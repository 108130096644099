

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Inicio from "./pages/inicio";
import Contacto from "./pages/contacto";
import Nosotros from "./pages/nosotros";
import Proyectos from "./pages/proyectos";



function Main() {
    return (
        <div>
            <Routes>
                <Route path='/' element={<Inicio/>}/>
                <Route path='/contacto' element={<Contacto/>}/>
                <Route path='/nosotros' element={<Nosotros/>}/>
                <Route path='/proyectos' element={<Proyectos/>}/>
            </Routes>
        </div>
    );
}

export default Main;

