import {Link} from "react-router-dom";


function Contacto() {
    return(
        <main>

            <div className="contact-section pt-145 pb-100 pt-lg-60 pb-lg-15">
                <div className="container">
                    <div className="row gx-4 gx-xxl-5">
                        <div className="col-lg-5">
                            <div className="contact-info-wrapper me-xxl-5 pe-xxl-5 mb-45">
                                <h2 className="sect-title mb-5">Conéctate con Nosotros</h2>
                                <div className="single-info-box mb-50">
                                    <div className="icon">
                                        <i className="bi bi-telephone"></i>
                                    </div>
                                    <span className="mb-10">+57 310 604 1535</span>
                                </div>
                                <div className="single-info-box mb-50">
                                    <div className="icon">
                                        <i className="bi bi-geo-alt"></i>
                                    </div>
                                    <span className="mb-10">Colombia,</span>
                                    <span>Santander de Quilichao - cauca</span>
                                </div>
                                <div className="single-info-box mb-50">
                                    <div className="icon">
                                        <i className="bi bi-envelope"></i>
                                    </div>
                                    <span className="mb-10">contacto@ondersoft.com.co</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contact-content-wrapper mb-45">
                                <div className="row gx-4 gx-xxl-5 px-0 align-items-center mb-25">
                                    <div className="col-xxl-8 col-md-7">
                                        <div className="text-center text-md-start">
                                            <p className="text-white mb-40">Desbloquea el potencial de tu negocio con soluciones de software a medida.
                                                Desde aplicaciones web hasta desarrollo móvil,
                                                nuestro equipo experto está listo para convertir tus ideas en realidad. Maximiza la eficiencia,
                                                impulsa la innovación y alcanza tus objetivos con nuestra experiencia en desarrollo de software.
                                                ¡Descubre cómo podemos llevar tu empresa al siguiente nivel hoy mismo!</p>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-md-5">
                                        <div
                                            className="text-center text-md-end team-social-three social-style-five d-block mb-40">
                                            <a href="#">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                            <a className="me-0" href="#">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact-map pb-40 mb-xxl-4">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!4v1707162262948!6m8!1m7!1sIMJAmIp2OSravIEet1DgGw!2m2!1d3.005623162105571!2d-76.48611004043983!3f316.60419022192593!4f-3.4449292895830723!5f0.7820865974627469"
                                        className="google-map-frame" allowFullScreen="" loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                                <div className="contact-form-two">
                                    <h3 className="post-title mb-50">Send Us Message</h3>
                                    <form className="widget-form" id="">
                                        <div className="row gx-4 gx-xxl-5 px-0">
                                            <div className="col-md-12 mb-6">
                                                <label className="fs-16 text-white">Nombre completo</label>
                                                <input required type="text" name="nombre_completo" placeholder="Jhon lind Zaens"/>
                                            </div>
                                            <div className="col-md-6 mb-6">
                                                <label className="fs-16 text-white">Celular</label>
                                                <input required type="number" name="celular" placeholder="311 8888 8888"/>
                                            </div>
                                            <div className="col-md-6 mb-6">
                                                <label className="fs-16 text-white">Correo electronico</label>
                                                <input required type="email" name="correo" placeholder="ejemplo@ejemplo.com"/>
                                            </div>
                                            <div className="col-md-12 mb-10">
                                                <label className="fs-16 text-white">Mensaje</label>
                                                <textarea required name="mensaje" placeholder="Escribe tu mensaje..."></textarea>
                                            </div>
                                            <div className="col-12">
                                                <button className="theme_btn">Enviar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="call-to-action pt-100 pb-100">
                <img className="call-shape shape-one" src="/lib/techy/img/shape/line-round-8c.svg" alt="shape"/>
                <img className="call-shape shape-two d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-39c.svg" alt="icon"/>
                <img className="call-shape shape-three d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-40c.svg"
                     alt="icon"/>
                <img className="call-shape shape-four d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-41c.svg" alt="icon"/>
                <img className="call-shape shape-five d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-42c.svg" alt="icon"/>
                <img className="call-shape shape-six d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-43c.svg" alt=""/>
                <img className="call-shape shape-seven d-none d-lg-inline-block" src="/lib/techy/img/icon/icon-44c.svg" alt=""/>
                <div className="container">
                    <div className="row gx-4 gx-xxl-5 justify-content-center">
                        <div className="col-xl-8 col-md-8">
                            <div className="call-to-wrapper">
                                <h3 className="title-big mb-60" >
                                    ¿Tienes un proyecto en mente? ¡Empecemos a trabajar!
                                </h3>
                                <Link className="theme_btn" to="/contacto">Comunícate con nosotros</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    );

}

export default Contacto;


