
import {Link} from "react-router-dom";

function Nosotros() {
    return (
        <main>

            <div className="page-title-area pt-210 pb-150 pt-lg-120 pb-lg-125 pb-md-100">
                <img className="page-title-shape shape-one " src="/lib/techy/img/shape/star-10d.svg" alt="shape"/>
                <img className="page-title-shape shape-three" src="/lib/techy/img/shape/star-2.svg" alt="shape"/>
                <img className="page-title-shape shape-four d-none d-md-inline-block "
                     src="/lib/techy/img/shape/star-4b.svg"
                     alt="shape"/>
                <img className="page-title-shape shape-five d-none d-lg-inline-block "
                     src="/lib/techy/img/shape/line-round-9d.svg"
                     alt="shape"/>
                <img className="page-title-shape shape-six" src="/lib/techy/img/shape/star-5b.svg" alt="shape"/>
                <img className="page-title-shape shape-seven" src="/lib/techy/img/shape/star-5b.svg" alt="shape"/>
                <img className="page-title-shape shape-eight d-none d-xl-inline-block"
                     src="/lib/techy/img/page-title/img-01.png"
                     alt="img"/>
                <img className="page-title-shape shape-nine d-none d-xl-inline-block"
                     src="/lib/techy/img/page-title/img-02.png"
                     alt="img"/>

                <div className="container">
                    <div className="row gx-4 gx-xxl-5 align-items-center">
                        <div className="col-xl-6 col-md-6">
                            <div className="page-title-wrapper text-md-start text-center">
                                <h2 className="page-title mb-20">Quienes somos</h2>
                                <nav aria-label="breadcrumb">
                                    <ul className="breadcrumb list-none justify-content-center justify-content-md-start">
                                        <li><Link to="/">Inicio</Link></li>
                                        <li><a href="#">Pages</a></li>
                                        <li className="active" aria-current="page">Nosotros</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="techy-feature pt-150 pt-lg-100 pb-30 pb-lg-15">
                <div className="container">
                    <div className="row gx-4 gx-xxl-5 justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="feature-style-three text-center mb-45">
                                <div className="icon"><img src="/lib/techy/img/icon/icon-15b.svg" alt="icon"/></div>
                                <h3>
                                    <Link className="sect-title-two" to="/proyectos">Aplicaciones nativas</Link>
                                </h3>
                                <p>Descubre nuestras aplicaciones móviles desarrolladas específicamente para cada
                                    plataforma, brindando la mejor experiencia de usuario y rendimiento optimizado.
                                    Diseñadas y optimizadas para iOS y Android.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="feature-style-three text-center mb-45">
                                <div className="icon"><img src="/lib/techy/img/icon/icon-16b.svg" alt="icon"/></div>
                                <h3>
                                    <Link className="sect-title-two" to="/proyectos">Precios simples</Link>
                                </h3>
                                <p>Experimenta nuestra sencilla estructura de precios diseñada para adaptarse a tus
                                    necesidades y presupuesto, sin complicaciones innecesarias.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8">
                            <div className="feature-style-three text-center mb-45">
                                <div className="icon"><img src="/lib/techy/img/icon/icon-17b.svg" alt="icon"/></div>
                                <h3>
                                    <Link className="sect-title-two" to="/proyectos">Soporte 24/7</Link>
                                </h3>
                                <p>Obtén asistencia y soporte técnico en cualquier momento del día, los 7 días de la
                                    semana, para resolver cualquier consulta o problema que puedas tener.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="techy-newsletter pt-120 pb-95 pt-md-20">
                <div className="container">
                    <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <div className="block-style-two text-center">
                                <img className="news-shape-one" src="/lib/techy/img/shape/line-round-3a.svg"
                                     alt="shape"/>
                                <h3>¿Tienes un proyecto en mente? ¡Empecemos a trabajar!</h3>
                                <Link to="/contacto" className="theme_btn">Contactanos</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-techy about-techy-4d pt-140 pb-75 pt-lg-60 pb-lg-15">
                <img className="about-shape-4d shape-1b d-none d-xl-inline-block" src="/lib/techy/img/shape/star-3b.svg"
                     alt="shape"/>
                <img className="about-shape-4d shape-2b d-none d-lg-inline-block" src="/lib/techy/img/shape/star-4b.svg"
                     alt="shape"/>
                <img className="about-shape-4d shape-3b d-none d-md-inline-block" src="/lib/techy/img/shape/star-4b.svg"
                     alt="shape"/>
                <img className="about-shape-4d shape-4b d-none d-md-inline-block" src="/lib/techy/img/shape/star-7c.svg"
                     alt="shape"/>
                <img className="about-shape-4d shape-5b d-none d-xl-inline-block" src="/lib/techy/img/shape/star-4b.svg"
                     alt="shape"/>
                <div className="container">
                    <div className="row gx-4 gx-xxl-5 align-items-center">
                        <div className="col-lg-7">
                            <div className="about-img-wrapper-four position-relative mb-45">
                                <img className="main-img" src="/me.png" alt="img"/>
                                <img className="main-img-two d-none d-xl-inline-block"
                                     src="/lib/techy/img/about/about-4d.jpg" alt="img"/>
                                <img className="shape-one" src="/lib/techy/img/shape/line-round-2a.svg" alt="shape"/>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="block-style-one pl-lg-20 mb-45">
                                <h2 className="mb-15">Descubre OnderSoft: Donde la Innovación y la Tecnología se
                                    Unen</h2>
                                <p>OnderSoft es el fruto del espíritu emprendedor de Andrés Felipe González Chantre, un
                                    visionario colombiano apasionado por el desarrollo de software. Con un enfoque en
                                    las últimas tecnologías tanto web como móviles, nuestra empresa se ha convertido en
                                    un referente en la industria.</p>
                                <p>Con OnderSoft, te sumergirás en un mundo de posibilidades tecnológicas, donde cada
                                    proyecto es una oportunidad para transformar ideas en soluciones innovadoras y
                                    escalables. Nos comprometemos a ofrecerte resultados excepcionales, respaldados por
                                    nuestra garantía de devolución de dinero y nuestro soporte en línea disponible las
                                    24 horas, los 7 días de la semana.</p>
                                <a className="theme_btn mt-60" href="/proyectos">Conoce Más</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="techy-newsletter pt-120 pb-95 pt-md-20">
                <div className="container">
                    <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <div className="block-style-two text-center">
                                <img className="news-shape-one" src="/lib/techy/img/shape/line-round-3a.svg" alt="shape"/>
                                <h3>¡Tienes un proyecto en mente? ¡Comencemos a trabajar juntos!</h3>
                                <p>¿Estás listo para dar vida a tu visión digital? OnderSoft, una empresa colombiana
                                    fundada por el emprendedor joven Andrés Felipe González Chantre, está aquí para
                                    convertir tus ideas en realidad. Nos destacamos en el desarrollo de software
                                    utilizando las últimas tecnologías tanto web como móviles.</p>
                                <Link to="/contacto" className="theme_btn">Contáctanos</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    );
}

export default Nosotros;
